import React from 'react';

const ComparableProperties = ({ comps, stats, formatNumber, formatCurrency }) => {
  return (
    <div>

      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-lg font-semibold mb-4">Comparables Search Criteria</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">Square Footage Range</p>
            <p className="font-medium">
              {formatNumber(stats.criteria_used.square_feet_range.min)} - {formatNumber(stats.criteria_used.square_feet_range.max)} sq ft
              <span className="text-gray-500 ml-2">({stats.criteria_used.square_feet_range.percentage})</span>
            </p>
          </div>
          <div>
            <p className="text-gray-600">Search Radius</p>
            <p className="font-medium">
              {formatNumber(stats.criteria_used.distance.feet)} ft
              <span className="text-gray-500 ml-2">({stats.criteria_used.distance.miles})</span>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4">
          Comparable Properties ({stats.total_comps})
        </h2>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left border-b">
                <th className="p-3">Address</th>
                <th className="p-3">Sale Date</th>
                <th className="p-3">Price</th>
                <th className="p-3">$/SqFt</th>
                <th className="p-3">SqFt</th>
                <th className="p-3">Beds</th>
                <th className="p-3">Baths</th>
                <th className="p-3">Units</th>
                <th className="p-3">Distance</th>
              </tr>
            </thead>
            <tbody>
              {comps.map((prop, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="p-3">{prop.address}</td>
                  <td className="p-3">{prop.sale_date}</td>
                  <td className="p-3">{formatCurrency(prop.sale_price)}</td>
                  <td className="p-3">${prop.price_per_sqft}</td>
                  <td className="p-3">{formatNumber(prop.square_feet)}</td>
                  <td className="p-3">{prop.bedrooms}</td>
                  <td className="p-3">{prop.bathrooms}</td>
                  <td className="p-3">{prop.number_of_units}</td>
                  <td className="p-3">{formatNumber(prop.distance_feet)} ft</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ComparableProperties;
