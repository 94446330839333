import React from 'react';

const PriceAnalysis = ({ stats }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h2 className="text-xl font-semibold mb-4">Price Analysis and Value Estimates</h2>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b">
              <th className="p-3 w-1/3">Metric</th>
              <th className="p-3 text-right">Price/SqFt</th>
              <th className="p-3 text-right">Est. Value</th>
            </tr>
          </thead>
          <tbody>
            {stats.metrics.map((metric, index) => (
              <tr key={index} className="border-b">
                <td className="p-3">{metric.name}</td>
                <td className="p-3 text-right">
                  {metric.price_per_sqft 
                    ? metric.price_per_sqft.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    : 'N/A'}
                </td>
                <td className="p-3 text-right">
                  {metric.estimated_value 
                    ? Math.round(metric.estimated_value).toLocaleString('en-US')
                    : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceAnalysis;
