import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PriceTrendChart = ({ historicalTrend }) => {
  if (!historicalTrend || historicalTrend.length === 0) return null;

  const formatYAxis = (value) => `$${Math.round(value)}`;
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;
    
    return (
      <div className="bg-white p-4 border rounded shadow mb-8">
        <p className="font-medium">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {entry.name}: ${Math.round(entry.value)}
            {entry.dataKey === 'price_per_sqft' && 
              ` (${payload[0].payload.num_transactions} transactions)`}
            {entry.dataKey === 'moving_average' && 
              ` (${payload[0].payload.window_transactions} transactions)`}
          </p>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <div className="mb-4">
        <h2 className="text-xl font-semibold">Price per Square Foot Trend</h2>
      </div>
      <div className="h-96">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={historicalTrend} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tickFormatter={(date) => {
                const [year, month] = date.split('-');
                return `${month}/${year.slice(2)}`;
              }}
              interval={Math.floor(historicalTrend.length / 12)}
            />
            <YAxis tickFormatter={formatYAxis} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="price_per_sqft" 
              stroke="#8884d8" 
              name="Monthly Average"
              dot={false}
            />
            <Line 
              type="monotone" 
              dataKey="moving_average" 
              stroke="#82ca9d" 
              name="12-Month Moving Average"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 text-sm text-gray-600">
        Note: Moving average is calculated as the weighted average of transactions over the previous 12 months
      </div>
    </div>
  );
};

export default PriceTrendChart;