import React from 'react';

const BasicPropertyInfo = ({ property, formatNumber, formatCurrency }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h2 className="text-xl font-semibold mb-4 flex items-center gap-2">
        Reference Property
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <p className="text-gray-600">Address</p>
          <p className="font-medium">{property.address}</p>
        </div>
        <div>
          <p className="text-gray-600">OPA Number</p>
          <p className="font-medium">{property.parcel_number}</p>
        </div>
        <div>
          <p className="text-gray-600">Zip Code</p>
          <p className="font-medium">{property.zip_code}</p>
        </div>
        <div>
          <p className="text-gray-600">Square Feet</p>
          <p className="font-medium">{formatNumber(property.square_feet)}</p>
        </div>
        <div>
          <p className="text-gray-600">Bedrooms</p>
          <p className="font-medium">{property.bedrooms}</p>
        </div>
        <div>
          <p className="text-gray-600">Bathrooms</p>
          <p className="font-medium">{property.bathrooms}</p>
        </div>
        <div>
          <p className="text-gray-600">Units</p>
          <p className="font-medium">{property.number_of_units}</p>
        </div>
        <div>
          <p className="text-gray-600">Year Built</p>
          <p className="font-medium">{property.year_built || 'N/A'}</p>
        </div>
        <div>
          <p className="text-gray-600">Assessed Value</p>
          <p className="font-medium">{formatCurrency(property.market_value)}</p>
        </div>
      </div>
    </div>
  );
};

export default BasicPropertyInfo;
