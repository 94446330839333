import React from 'react';
import { AlertTriangle } from 'lucide-react';

const ViolationsTable = ({ violations }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h2 className="text-xl font-semibold mb-4 flex items-center gap-2">
        <AlertTriangle className="h-6 w-6 text-red-500" />
        Active Violations ({violations.length})
      </h2>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b">
              <th className="p-3">Date</th>
              <th className="p-3">Case #</th>
              <th className="p-3">Code</th>
              <th className="p-3">Description</th>
              <th className="p-3">Status</th>
            </tr>
          </thead>
          <tbody>
            {violations.map((violation, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="p-3">
                  {new Date(violation.violationdate).toLocaleDateString()}
                </td>
                <td className="p-3">{violation.casenumber}</td>
                <td className="p-3">{violation.violationcode}</td>
                <td className="p-3">{violation.violationcodetitle}</td>
                <td className="p-3">
                  <span className="inline-block px-2 py-1 text-xs font-semibold bg-yellow-100 text-yellow-800 rounded">
                    {violation.violationstatus}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViolationsTable;
